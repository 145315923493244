import request from '@/libs/request'

export default async function signSdk (curUrl) {
  if (window.wxjssdkReady) {
    setTimeout(() => {
      console.log('发射wxready事件')
      window.eventBus.emit('wxjssdkReady')
    }, 1000)
    return
  }
  const res = await request.get('api/mpWebSite/jssdk', {
    params: {
      signUrl: curUrl
    }
  })
  console.log('签名信息', res)
  // 微信jssdk签名
  if (res && res.code === 0) {
    const signInfo = {
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: res.data.appId, // 必填，公众号的唯一标识
      timestamp: res.data.timestamp, // 必填，生成签名的时间戳
      nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
      signature: res.data.signature, // 必填，签名
      jsApiList: res.data.jsApiList // 必填，需要使用的JS接口列表
    }
    console.log('开始配置jsapi config', signInfo)
    window.wx.config(signInfo)
    window.wx.ready(wxres => {
      console.log('jssdk签名成功', wxres)
      window.eventBus.emit('wxjssdkReady', wxres)
      window.wxjssdkReady = true
    })
    window.wx.error(err => {
      alert('wx.config失败')
      console.log('wx.config失败', err)
    })
  } else {
    alert('获取jssdk签名信息失败')
    console.log('jssdk签名失败', res)
  }
}
