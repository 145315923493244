<template>
<div>
  <nav class="topbar fly" v-show="isNavFly">
    <router-link v-show="isDetail" class="iconfont icon-arrow-left" to="/"></router-link>
    <h1 class="logo" v-show="!isDetail">
      <img src="~@/assets/img/logo.png" alt="马上搜谱">
      <!-- <span>马上搜谱</span> -->
    </h1>
    <div class="search-bar">
      <span class="search-icon"></span>
      <input type="text" name="search" id="search" placeholder="找吉他谱？嗖一下，就有了">
    </div>
  </nav>
  <nav class="topbar">
    <router-link v-show="isDetail" class="iconfont icon-arrow-left" to="/"></router-link>
    <h1 class="logo" v-show="!isDetail">
      <img src="~@/assets/img/logo.png" alt="马上搜谱">
      <!-- <span>马上搜谱</span> -->
    </h1>
    <div class="search-bar">
      <span class="search-icon"></span>
      <input type="text" name="search" id="search" placeholder="找吉他谱？嗖一下，就有了">
    </div>
  </nav>
</div>
</template>

<script>
export default {
  props: {
    isNavFly: {
      type: Boolean,
      default: false
    },
    isDetail: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.topbar {
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 6px 16px;
  background: white;
  transition: all .3s;
  .icon-arrow-left {
    font-size: 33px;
    color: #6e6e6e;
    margin-left: -8px;
  }
  h1 {
    height: 100%;
    display: flex;
    align-items: center;
    span {
      font-size: 16px;
      font-weight: bold;
      display: block;
      white-space: nowrap;
    }
    img {
      max-height: 100%;
    }
  }
  .search-bar {
    margin-left: 12px;
    font-weight: normal;
    font-size: 14px;
    flex: 1;
    text-align: center;
    background-color: #eee;
    border-radius: 6px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 12px 0 0px;
    .search-icon {
      width: 30px;
      height: 30px;
      background-image: url("~@/assets/img/search_icon.png");
      background-size: contain;
    }
    [name=search] {
      border: none;
      background: transparent;
      flex: 1;
      width: 100%;
      outline: none;
      color: #1b1b1b;
    }
  }
}
.topbar.fly {
  transition: all .3s;
  position: fixed;
  top: 0;
  left: 0px;
  z-index: 100;
  background: white;
  right: 0px;
}
</style>
