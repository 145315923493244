<template>
  <div class="home">
    <HomeTopbar :isNavFly="isNavFly"></HomeTopbar>
    <!-- <swiper
      :modules="modules"
      :slides-per-view="1"
      :space-between="50"
      :autoplay="true"
      :centeredSlides="true"
      :slidesPerView="'auto'"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
      class="slide-wrap"
    >
      <swiper-slide v-for="item in slides" :key="item.img">
        <router-link to="/list?k=毛不易" class="slide-item">
          <img :src="item.img">
        </router-link>
      </swiper-slide>
    </swiper> -->
    <div class="recommend-wrap">
      <div class="subnav is-subnav-fly" v-show="isSubNavFly">
        <ul>
          <li class="active">
            <span class="iconfont icon-faxian1"></span>
          </li>
          <li>
            <span class="iconfont icon-wodeguanzhu"></span>
          </li>
          <li>
            <span class="iconfont icon-shoucang"></span>
          </li>
        </ul>
      </div>
      <div class="subnav">
        <ul>
          <li class="active">
            <span class="iconfont icon-faxian1"></span>
            <span class="text">推荐</span>
          </li>
          <li>
            <span class="iconfont icon-wodeguanzhu"></span>
            <span class="text">关注</span>
          </li>
          <li>
            <span class="iconfont icon-shoucang"></span>
            <span class="text">收藏</span>
          </li>
        </ul>
      </div>
      <ul class="recommend-list">
        <li @click="$router.push({name: 'detail', query: {id: 111}})">
          <h3>漠河舞厅</h3>
          <div class="base-info">
            <span>类型: 弹唱</span>
            <span>调号: C调</span>
          </div>
          <span class="author-info">作者: 吉他社工作室</span>
          <div class="desc">
            <span>这是一首描述东北老人的故事，C调编配</span>
          </div>
          <div class="sep-line"></div>
          <div class="op-info">
            <span title="浏览量" class="iconfont icon-yanjing">1111</span>
            <span title="点赞量" class="iconfont icon-dianzan">444</span>
            <span title="收藏量" class="iconfont icon-shoucang">23</span>
            <span title="下载量" class="iconfont icon-shangchuan">577</span>
          </div>
        </li>
        <li @click="$router.push({name: 'detail', query: {id: 222}})">
          <h3>同桌的你</h3>
          <div class="base-info">
            <span>类型: 弹唱</span>
            <span>调号: C调</span>
          </div>
          <span class="author-info">作者: 吉他社工作室</span>
          <div class="desc">
            <span>这是水木年华当年传唱大街小巷的一首歌曲</span>
          </div>
          <div class="sep-line"></div>
          <div class="op-info">
            <span title="浏览量" class="iconfont icon-yanjing">1111</span>
            <span title="点赞量" class="iconfont icon-dianzan">444</span>
            <span title="收藏量" class="iconfont icon-shoucang">23</span>
            <span title="下载量" class="iconfont icon-shangchuan">577</span>
          </div>
        </li>
        <li @click="$router.push({name: 'detail', query: {id: 333}})">
          <h3>姑娘</h3>
          <div class="base-info">
            <span>类型: 弹唱</span>
            <span>调号: C调</span>
          </div>
          <span class="author-info">作者: 吉他社工作室</span>
          <div class="desc">
            <span>这是一首描述东北老人的故事，C调编配</span>
          </div>
          <div class="sep-line"></div>
          <div class="op-info">
            <span title="浏览量" class="iconfont icon-yanjing">1111</span>
            <span title="点赞量" class="iconfont icon-dianzan">444</span>
            <span title="收藏量" class="iconfont icon-shoucang">23</span>
            <span title="下载量" class="iconfont icon-shangchuan">577</span>
          </div>
        </li>
        <li @click="$router.push({name: 'detail', query: {id: 111}})">
          <h3>漠河舞厅</h3>
          <div class="base-info">
            <span>类型: 弹唱</span>
            <span>调号: C调</span>
          </div>
          <span class="author-info">作者: 吉他社工作室</span>
          <div class="desc">
            <span>这是一首描述东北老人的故事，C调编配</span>
          </div>
          <div class="sep-line"></div>
          <div class="op-info">
            <span title="浏览量" class="iconfont icon-yanjing">1111</span>
            <span title="点赞量" class="iconfont icon-dianzan">444</span>
            <span title="收藏量" class="iconfont icon-shoucang">23</span>
            <span title="下载量" class="iconfont icon-shangchuan">577</span>
          </div>
        </li>
        <li @click="$router.push({name: 'detail', query: {id: 111}})">
          <h3>漠河舞厅</h3>
          <div class="base-info">
            <span>类型: 弹唱</span>
            <span>调号: C调</span>
          </div>
          <span class="author-info">作者: 吉他社工作室</span>
          <div class="desc">
            <span>这是一首描述东北老人的故事，C调编配</span>
          </div>
          <div class="sep-line"></div>
          <div class="op-info">
            <span title="浏览量" class="iconfont icon-yanjing">1111</span>
            <span title="点赞量" class="iconfont icon-dianzan">444</span>
            <span title="收藏量" class="iconfont icon-shoucang">23</span>
            <span title="下载量" class="iconfont icon-shangchuan">577</span>
          </div>
        </li>
        <li @click="$router.push({name: 'detail', query: {id: 111}})">
          <h3>漠河舞厅</h3>
          <div class="base-info">
            <span>类型: 弹唱</span>
            <span>调号: C调</span>
          </div>
          <span class="author-info">作者: 吉他社工作室</span>
          <div class="desc">
            <span>这是一首描述东北老人的故事，C调编配</span>
          </div>
          <div class="sep-line"></div>
          <div class="op-info">
            <span title="浏览量" class="iconfont icon-yanjing">1111</span>
            <span title="点赞量" class="iconfont icon-dianzan">444</span>
            <span title="收藏量" class="iconfont icon-shoucang">23</span>
            <span title="下载量" class="iconfont icon-shangchuan">577</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeTopbar from '@/components/HomeTopbar.vue'
// Import Swiper Vue.js components
// import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination, Autoplay } from 'swiper'

// Import Swiper styles
// import 'swiper/css'
import 'swiper/css/bundle'

export default {
  name: 'HomeView',
  components: {
    HomeTopbar
    // Swiper,
    // SwiperSlide
  },
  data () {
    return {
      slides: [
        { img: require('@/assets/demopic/slide0.png') },
        { img: require('@/assets/demopic/slide1.png') },
        { img: require('@/assets/demopic/slide2.png') }
      ],
      isNavFly: false,
      isSubNavFly: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onWindowScroll)
  },
  beforeMounted () {
    this.onLeavePage()
  },
  methods: {
    onWindowScroll () {
      // const swiperHeight = 200
      const topbarHeight = 50
      // const subnavLightHeight = 41

      console.log('监听scroll')
      this.isNavFly = document.scrollingElement?.scrollTop >= topbarHeight
      this.isSubNavFly = document.scrollingElement?.scrollTop >= (topbarHeight)
    },
    onLeavePage () {
      console.log('移除scroll')
      window.removeEventListener('scroll', this.onWindowScroll)
    }
  },
  setup () {
    const onSwiper = (swiper) => {
      console.log(swiper)
    }
    const onSlideChange = () => {
      console.log('切换轮播')
    }
    return {
      onSwiper,
      onSlideChange,
      modules: [Pagination, Autoplay]
    }
  }
}
</script>

<style lang="scss" scoped>
// 原木色ecb78f
body {
  color: #ecb78f
}
.slide-wrap {
  height: 200px;
  border-radius: 8px;
  margin: 6px 6px 0px;
  a.slide-item {
    height: 100%;
    display: block;
    img {
      max-height: 100%;
    }
  }
}
.recommend-wrap {
  background-color: #f5f6f7;
  margin: 6px;
  .subnav {
    border-radius: 8px;
    background: white;
    ul {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      padding: 8px;
      list-style: none;
      li {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        svg {
          font-size: 50px;
          display: block;
        }
        .flow {
          color: blue;
        }
        .collect {
          color: green;
        }
        .iconfont {
          font-size: 26px;
          color: #5c3d26;
        }
        span.text {
          margin-top: 4px;
        }
      }
      li.active .iconfont, li.active span.text {
        color:#ff8832;
      }
    }
  }
  .subnav.is-subnav-fly {
    transition: all .3s;
    position: fixed;
    top: 50px;
    left: 0px;
    right: 0px;
    border-bottom: 1px solid #ccccccc4;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 101;
    ul li .iconfont {
      font-size: 22px;
    }
  }
  .recommend-list {
    margin-top: 10px;
    padding: 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    li {
      margin: 5px 0;
      border-radius: 8px;
      background: white;
      padding: 8px 12px;
      h3 {
        font-size: 16px;
        margin-bottom: 3px;
      }
      .base-info {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        span:not(:last-child) {
          border-right: 1px solid #cccccc6b;
          padding-right: 4px;
          margin-right: 4px;
        }
      }
      .author-info {
        display: flex;
        text-align: left;
      }
      .desc {
        margin-top: 2px;
        color: #5a3227;
      }
      .op-info {
        display: flex;
        align-items: center;
        align-content: stretch;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        border-top: 1px solid #cccccc6b;
        margin-top: 9px;
        padding-top: 9px;
        span::before {
          margin-right: 3px;
        }
      }
    }
  }
}
</style>
