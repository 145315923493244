import axios from 'axios'
import router from '@/router'

axios.interceptors.request.use(config => {
  if (config.url !== 'api/mpWebSite/oauth') {
    config.headers.authorization = localStorage.getItem('webtoken')
  }
  config.url = '//' + process.env.API_HOST + '/' + config.url
  console.log('请求参数', config)
  config.params = config.params || {}
  config.params.projectId = '1'
  config.data = config.data || {}
  config.data.projectId = '1'
  return config
})

axios.interceptors.response.use((res) => {
  const { data } = res
  // 要求服务端返回 200 状态码，且json里包含 code 字段。
  // code为0是成功， 401是没登录(比如token过期了。一般不会因为没有token而导致401，因为router那里做了判断，如果没有本地token就认为是没登录，就已经跳走了)。
  if (!data || data.code !== 0) {
    if (data.code === 401) {
      // 鉴权失败，需要重新登录
      if (!window._loginRetry) {
        router.push({
          path: '/oauth', replace: true
        })
        window._loginRetry = true
      } else {
        alert('登录失败')
      }
    } else {
      alert('网络错误')
    }
  }
  return data
}, (error) => {
  // Message.error('服务内部错误')
  // 对响应错误做点什么
  return Promise.reject(error)
})

export default axios
