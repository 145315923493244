import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import signSdk from '@/utils/jssdk'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/list',
    name: 'list',
    component: () => import(/* webpackChunkName: "list" */ '../views/ListView.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import(/* webpackChunkName: "detail" */ '../views/DetailView.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/uc',
    name: 'uc',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    children: [
      {
        path: '/collect',
        name: 'collect',
        component: () => import(/* webpackChunkName: "detail" */ '../views/DetailView.vue')
      },
      {
        path: '/follow',
        name: 'follow',
        component: () => import(/* webpackChunkName: "detail" */ '../views/DetailView.vue')
      },
      {
        path: '/order',
        name: 'order',
        component: () => import(/* webpackChunkName: "detail" */ '../views/DetailView.vue'),
        children: [
          {
            name: 'orderList',
            path: '/list',
            component: () => import(/* webpackChunkName: "detail" */ '../views/DetailView.vue')
          },
          {
            name: 'orderDetail', // 负责查看个人中心订单详情，如果还没支付完，会显示支付按钮
            path: '/detail',
            component: () => import(/* webpackChunkName: "detail" */ '../views/DetailView.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/oauth',
    name: 'OAuth',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "oauth" */ '../views/OAuth.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  debugger
  console.log('路由前，判断是否跳登录')
  if (to.meta.auth === true) {
    console.log('需要登录')
    if (!localStorage.getItem('webtoken')) {
      return {
        name: 'OAuth',
        query: {
          fromRouteName: to.name
        }
      }
    }
    return true
  } else {
    console.log('不需要登录')
  }
})

router.afterEach((to, from, failure) => {
  console.log('路由后')
  if (to.name !== 'OAuth') {
    // 发起页面jssdk签名
    signSdk(location.href.split('#')[0])
  }
})

// 单页应用，只全局签名一次
// console.log('全局签名一次')
// signSdk(location.href.split('#')[0]).then(res => {
//   window.wxsdkReady = true
// })

export default router
